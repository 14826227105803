import React, { useEffect, useRef, useState } from "react";
import "moment-timezone";
import {
  ApiGet,
  ApiGetNoAuth,
  ApiPost,
  ApiPut,
} from "../../../helpers/API/ApiData";
import "../Team/tema.scss";
import CustomCalendar from "../Calendar/calender";
import moment from "moment";
import { toast } from "react-toastify";
import { TailSpin } from "react-loader-spinner";
import LeadNotesModel from "./leadnotesModel";
import UploadIconR from "./uploadIcon";
import GreenCheckIcon from "./greenCheckIco";
import EditIconSvg from "./editIcon";
import Loader from "../../../helpers/loader";
import EyeIcon from "./eyeIcon";
import CloseIcon from "../closeIcon";
import { NavLink } from "react-router-dom";

export default function VattelnFallLead() {
  const [leadData, setLeadData] = useState({});
  const [appoinmentData, setAppoinmentData] = useState();
  const [modal, setModal] = useState(false);
  const [toggle, setToggle] = useState(false);
  const [ownMessage, setOwnMessage] = useState("");
  const [leadStatus, setLeadStatus] = useState();
  const [meetingDate, setMeetingDate] = useState();
  const [noteModel, setNoteModel] = useState(false);
  const [type, setType] = useState("add");
  const [note, setNote] = useState("");
  const [selectedItem, setSelectedItem] = useState();
  const [loading, setLoading] = useState(false);
  const [itShow, setItShow] = useState("");
  const [dropModel, setDropMopdel] = useState(false);
  const [reason, setReason] = useState();
  const [error, setError] = useState();
  const [laoding, setLoadings] = useState(false);
  const [updatedStaus, setUpdatedStatus] = useState();
  const [leadNotesArray, setLeadNotesArray] = useState([]);
  const [leadNotesModel, setLeadNotesModel] = useState(false);
  const [singleLeadNote, setSingleLeadNote] = useState();
  const [leadShow, setLeadShow] = useState("");
  const [notizen, setNotizen] = useState("");
  const [notizenArray, setNotizenArray] = useState([]);
  const [modalType, setModalType] = useState("");
  const [docModel, setDocModel] = useState(false);
  const [fileCheckBox, setFileCheckBox] = useState("");
  const [wpFields, setWpFields] = useState({});
  const [uploadLoader, setUploaderLoader] = useState(false);
  const [newDocModel, setNewDocModel] = useState(false);
  const [docLoader, setDocLoader] = useState(false);

  const url = window.location.href;
  const urlObj = new URL(url);
  const id = urlObj.searchParams.get("id");
  const Vattentype = urlObj.searchParams.get("type");
  console.log("Vattentype", Vattentype);

  const staticFileNameArray = [
    { id: 1, name: "grundriss", text: "Grundriss", loader: false },
    { id: 2, name: "fotoHeizung", text: "Foto Heizung", loader: false },
    { id: 3, name: "fotoHeizkorper", text: "Foto Heizkörper", loader: false },
    {
      id: 4,
      name: "fotoAubeneinheit",
      text: "Foto Aufstellort Außeneinheit",
      loader: false,
    },
    {
      id: 5,
      name: "geschatzterAuben",
      text: "Geschätzter Leitungsweg außen",
      loader: false,
    },
    {
      id: 6,
      name: "geschatzterInnen",
      text: "Geschätzter Leitungsweg innen",
      loader: false,
    },
    { id: 7, name: "verbrauchsdaten", text: "Verbrauchsdaten", loader: false },
    { id: 8, name: "sonstiges", text: "Sonstiges", loader: false },
  ];

  const [FileNameArray, setFileNameArray] = useState(staticFileNameArray);
  useEffect(() => {
    if (docModel) {
      document.body.style.overflow = "hidden";
    } else {
      document.body.style.overflow = "";
    }
  });
  const resonData = [
    { id: 1, reason: "Kein Interesse an Photovoltaik" },
    { id: 2, reason: "Finanzielle Einschränkungen" },
    { id: 3, reason: "Kein Bedarf aufgrund des geringen Stromverbrauch" },
    { id: 4, reason: "Falsche preisliche Vorstellung" },
    {
      id: 5,
      reason:
        "Keine Installationsmöglichkeit / Projekt kann  nicht umgesetzt werden",
    },
    { id: 6, reason: "nur aus Höflichkeit eingetragen" },
    { id: 7, reason: "Keine Entscheidungskompetenz" },
    { id: 8, reason: "Veränderung der Lebensumstände" },
    { id: 9, reason: "Umsetzung > 6 Monate" },
    { id: 10, reason: "Kunde nach min. 6 Anrufversuchen nicht erreichbar." },
    { id: 11, reason: "Kontaktdaten falsch." },
  ];

  const userInfo = JSON.parse(localStorage.getItem("userinfo"));

  const handleChangeImage = async (e, item) => {
    const updatedItem = { ...item, loader: true };
    setFileNameArray((prevItems) =>
      prevItems.map((item) => (item.id === updatedItem.id ? updatedItem : item))
    );
    item.loader = true;
    const files = e.target.files?.[0];
    let formData = new FormData();
    formData.append("field", e.target.name);
    formData.append("image", files);
    formData.append("leadId", id);
    try {
      await ApiPost(`vattenfall/uploadLeadFiles`, formData);
      await getDataById();
      toast.success("File upload Successfully");
    } catch (error) {
      toast.error("SOmrhing went wrong at server!");
      console.error("error");
    } finally {
      const updatedItem = { ...item, loader: false };
      setFileNameArray((prevItems) =>
        prevItems.map((item) =>
          item.id === updatedItem.id ? updatedItem : item
        )
      );
    }
  };

  const handleUploadDoc = async (e) => {
    try {
      setDocLoader(true);
      let formData = new FormData();
      formData.append("image", e.target?.files?.[0]);
      const response = await ApiPost(`user/upload-image`, formData);
      await handleDocSubmit(response?.data?.payload);
    } catch (error) {
    } finally {
      setDocLoader(false);
    }
  };

  const handleDocSubmit = async (response) => {
    const payload = {
      vattenfallDocument: response,
    };
    await ApiPut(`vattenfall/uploadVattenDocument?id=${id}`, payload);
    await getDataById();
  };

  const handleOnClick = async () => {
    let formData = new FormData();
    formData.append("field", "Ja");
    formData.append("Ja", leadData?.Ja);
    formData.append("leadId", id);
    try {
      await ApiPost(`vattenfall/uploadLeadFiles`, formData);
      await getDataById();
      toast.success("updated Successfully");
      setNewDocModel(false);
    } catch (error) {
      console.error("error");
    }
  };

  const getDataById = async () => {
    try {
      let response = await ApiGet(`vattenfall/getLead?id=${id}`);
      setLeadNotesArray(response?.data?.payload?.leadNotes);
      setNotizenArray(response?.data?.payload?.notizen);
      setLeadData(response?.data?.payload);
      setLeadStatus(response?.data?.payload?.status);
    } catch (error) {
      console.log(error);
    }
  };
  useEffect(() => {
    leadData && getDataById();
  }, []);

  const handleInput = (e) => {
    setOwnMessage(e.target.value);
  };

  const handleCalendar = () => {
    setModal(!modal);
  };

  const handleStatus = async (statuss) => {
    if (
      statuss?.toUpperCase() == "STORNO" ||
      statuss?.toUpperCase() == "ABSAGE"
    ) {
      setUpdatedStatus(statuss);
      setDropMopdel(!dropModel);
      return;
    } else {
      setToggle(!toggle);
      setLeadStatus(statuss);

      try {
        let body = {
          status: statuss,
        };
        let resp = await ApiPut(
          `vattenfall/updateLeadVatten${id ? `?id=${id}` : ""}`,
          body
        );
        toast.success("Status erfolgreich verändert.");
        console.log(
          " window.location.href = '/new-path';",
          resp?.data?.payload?.status == "VERKAUFT"
        );
        if (resp?.data?.payload?.status == "VERKAUFT") {
          window.location.href = "/archive";
        }
      } catch (error) {
        toast.error("Failed to update lead status");
        console.log(error);
      }
    }
  };

  const getAppointment = async () => {
    try {
      let response = await ApiGet(`vattenfall/getAppointment?leadId=${id}`);
      setAppoinmentData(response?.data?.payload);
      setNote(response?.data?.payload);
    } catch (error) {
      console.log(error);
    }
  };

  useEffect(() => {
    getAppointment();
  }, []);

  const today = new Date();
  const results =
    appoinmentData &&
    appoinmentData?.map((appointment) => {
      return new Date(appointment.appointmentDate) > today;
    });

  const updateMeeting = (item) => {
    setMeetingDate(item);
    setModal(!modal);
    setType("update");
  };

  const status =
    Vattentype == "project-wp"
      ? [
          "AUFMAß",
          "offen",
          "NICHT ERREICHT",
          "ZEITLICHE VERZÖGERUNG",
          "SPÄTER ANRUFEN",
          "STORNO",
          "ANGEBOTSBESPRECHUNG",
          "ANGEBOTSBESPRECHUNG 2",
          "ABSAGE",
          "VERKAUFT",
          "ANGEBOT ERSTELLEN",
          "FERTIGES ANGEBOT",
          "PLANUNG",
        ]
      : [
          "AUFMAß",
          "offen",
          "NICHT ERREICHT",
          "ZEITLICHE VERZÖGERUNG",
          "SPÄTER ANRUFEN",
          "STORNO",
          "ANGEBOTSBESPRECHUNG",
          "ANGEBOTSBESPRECHUNG 2",
          "ABSAGE",
          "VERKAUFT",
        ];

  const handleModels = (items) => {
    setSelectedItem(items);
    setNoteModel(!noteModel);
    let filterData = appoinmentData?.filter((item) => {
      return items?._id == item?._id;
    });
    setNote(filterData?.[0]);
  };

  const handleLeadsModels = (items, type, modalType) => {
    setModalType(modalType);
    setLeadShow(type);
    setLeadNotesModel(true);
    let filterData;
    if (modalType === "leadNotes") {
      filterData = leadNotesArray?.filter((item) => {
        return items?._id == item?._id;
      });
    }
    if (modalType === "notizen") {
      filterData = notizenArray?.filter((item) => {
        return items?._id == item?._id;
      });
    }
    setSingleLeadNote(filterData?.[0]);
  };

  const handleModelsChange = (e) => {
    setNote({ ...note, notes: e.target.value });
  };

  const updateNotes = async () => {
    try {
      const body = {
        notes: note?.notes,
      };
      setLoading(true);
      let response = await ApiPut(
        `vattenfall/updateAppointment?id=${selectedItem?._id}`,
        body
      );
      getAppointment();
      setLoading(false);
      setNoteModel(!noteModel);
      toast.success("Notizen aktualisiert");
    } catch (error) {
      console.log(error);
      setLoading(false);
      toast.success("Something went wrong!");
    }
  };
  const dropdownRef = useRef(null);

  useEffect(() => {
    function handleClickOutside(event) {
      if (dropdownRef.current && !dropdownRef.current.contains(event.target)) {
        setToggle(false);
      }
    }

    document.addEventListener("mousedown", handleClickOutside);

    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, [dropdownRef]);

  const handleChangeInput = (e) => {
    const { name, value } = e.target;
    setLeadData({ ...leadData, [name]: value });
  };
  const handleUserDetails = async () => {
    try {
      const newObj = {
        text: ownMessage,
        uid: userInfo?._id,
        createdAt: new Date(),
        updatedAt: new Date(),
        user_name: userInfo?.name,
      };
      const notizenObj = {
        text: notizen,
        uid: userInfo?._id,
        createdAt: new Date(),
        updatedAt: new Date(),
        user_name: userInfo?.name,
      };
      setLoading(true);
      let body = {
        name: leadData?.name,
        nachname: leadData?.nachname,
        telephon: leadData?.telephon,
        email: leadData?.email,
        strabe: leadData?.strabe,
        pLZ: leadData?.pLZ,
        location: leadData?.location,
        housetype: leadData?.housetype,
        satteldach: leadData?.satteldach,
        power_consumption: leadData?.power_consumption,
        roofFelt: leadData?.roofFelt,
        schornstein: leadData?.schornstein,
        aktuellkeinEAuto: leadData?.aktuellkeinEAuto,
        rooftype: leadData?.rooftype,
        accessibility: leadData?.accessibility,
        leadNotes: !!leadNotesArray?.length
          ? ownMessage
            ? [...leadNotesArray, newObj]
            : leadNotesArray
          : ownMessage
          ? [newObj]
          : [],
        notizen: !!notizenArray?.length
          ? notizen
            ? [...notizenArray, notizenObj]
            : notizenArray
          : notizen
          ? [notizenObj]
          : [],
      };
      let resp = await ApiPut(`vattenfall/updateLeadVatten?id=${id}`, body);
      uploadNewFiled();
      getAppointment();
      getDataById();
      setOwnMessage("");
      toast.success("Leaddaten aktualisiert");
    } catch (error) {
      console.log("error=====>>>>", error);
      toast.error("Ein Pflichtfeld ist nicht ausgefüllt, bitte prüfen.");
    } finally {
      setLoading(false);
    }
  };

  const removeFile = async (name, idx) => {
    const filterData = wpFields?.[name]?.filter((item, index) => index != idx);

    const copydata = { ...wpFields, [name]: filterData };
    await ApiPut(`vattenfall/updateVattenfallWPLead?id=${id}`, copydata);
    setWpFields({ ...wpFields, [name]: filterData });
  };

  const validation = () => {
    let isValid = true;
    let errors = {};
    if (!reason || reason?.trim() === "") {
      errors["reason"] = "Bitte überprüfe deine Eingabe";
      isValid = false;
    }

    setError(errors);
    return isValid;
  };
  const updateReason = async () => {
    if (validation()) {
      setLoadings(true);
      try {
        let body = {
          status: updatedStaus,
          reason: reason,
        };
        let resp = await ApiPut(`vattenfall/updateLeadVatten?id=${id}`, body);

        toast.success("status updated");
        getDataById();
        setReason("");
      } catch (error) {
        toast.error(error?.message);
      } finally {
        setDropMopdel(!dropModel);
        setLoadings(false);
      }
    }
  };
  const handleChange = (e) => {
    setReason(e.target.value);
    setError("");
  };

  async function downloadS3File() {
    try {
      const response = await fetch(leadData?.["vattenfallDocument"]);
      if (!response.ok) {
        throw new Error(`HTTP error! Status: ${response.status}`);
      }

      const blob = await response.blob();
      const url = window.URL.createObjectURL(blob);
      const a = document.createElement("a");
      a.href = url;
      a.download = "document";
      document.body.appendChild(a);
      a.click();
      document.body.removeChild(a);
      window.URL.revokeObjectURL(url);
    } catch (error) {
      console.error("Error downloading file:", error);
    }
  }
  const uploadImageFiled = async (e) => {
    try {
      setUploaderLoader(true);
      const { name } = e.target;
      let formData = new FormData();
      const files = Array.from(e.target.files);
      files.forEach((file) => formData.append("image", file));

      const response = await ApiPost(`user/uploadMultiImage`, formData);

      const uploadedImage = response?.data?.payload;

      let payload = {
        [name]: uploadedImage,
        vattenfallId: id,
      };

      if (wpFields?._id) {
        payload.id = wpFields?._id;
      }

      delete payload?._id;

      payload[name] = [
        ...(wpFields[name] || []),
        ...(Array.isArray(uploadedImage) ? uploadedImage : [uploadedImage]),
      ];
      // }

      await ApiPost(`vattenfall/createVattenfallWPLead`, payload);
      setWpFields({
        ...wpFields,
        [name]: [
          ...(wpFields[name] || []),
          ...(Array.isArray(uploadedImage) ? uploadedImage : [uploadedImage]),
        ],
      });
    } catch (error) {
      console.error("error:", error);
    } finally {
      setUploaderLoader(false);
    }
  };
  const getvattenfallWpLeadsData = async () => {
    try {
      const resposne = await ApiGet(`vattenfall/getVattenfallWPLead?id=${id}`);
      setWpFields(resposne?.data?.payload);
    } catch (error) {}
  };

  useEffect(() => {
    getvattenfallWpLeadsData();
  }, []);
  const uploadNewFiled = async () => {
    const payload = {};
    try {
      for (const [key, value] of Object.entries(wpFields)) {
        payload[key] = value;
      }
      payload.vattenfallId = id;
      if (wpFields?._id) {
        payload.id = wpFields?._id;
      }
      delete payload?._id;
      setUploaderLoader(true);
      const response = await ApiPost(
        `vattenfall/createVattenfallWPLead`,
        payload
      );
      toast.success("Erfolgreich aktualisiert");
    } catch (error) {
    } finally {
      setUploaderLoader(false);
    }
  };

  const handleWpChange = (e) => {
    const { name, value } = e.target;
    setWpFields({ ...wpFields, [name]: value });
  };

  return (
    <>
      {uploadLoader && <Loader />}
      {!modal ? (
        <div className="VattelnFallLead-main">
          <div className="VattelnFallLead-container">
            <div className="VattelnFallLead-title">
              <p>
                {`Terminierung "${leadData?.name} ${leadData?.nachname}" in "${leadData?.location}" errreichbar unter:
                "${leadData?.telephon}"`}
              </p>
              {Vattentype == "project-wp" && (
                <button onClick={() => setDocModel(true)}>
                  Dokument hochladen
                </button>
              )}

              {/* <button className="redirect-but-dddd" style={{ color: "#fff" }}>
                <a href={`/add-new?type=${Vattentype}`}>+ Lead</a>
              </button> */}
            </div>
            <div
              ref={dropdownRef}
              onClick={() => setToggle(!toggle)}
              className={
                leadStatus == "STORNO" || leadStatus == "ABSAGE"
                  ? "offen-ticket-div"
                  : leadStatus == "VERKAUFT"
                  ? "offen-ticket-div-status-col-2"
                  : "offen-ticket-div-status-col"
              }
            >
              <span
                className={
                  leadStatus == "STORNO" || leadStatus == "ABSAGE"
                    ? "status-color"
                    : leadStatus == "VERKAUFT"
                    ? "final-status-green"
                    : "status-color-new"
                }
              >
                {leadStatus?.toUpperCase()}
              </span>
              <div
                className={
                  leadStatus == "VERKAUFT" || leadStatus == "ABSAGE"
                    ? "display-no"
                    : "display-yes"
                }
              >
                {" "}
                {!toggle ? (
                  <svg
                    width="18"
                    height="11"
                    viewBox="0 0 18 11"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      d="M9.54661 9.93141L17.5116 1.57191C17.6446 1.43243 17.7187 1.24712 17.7187 1.05441C17.7187 0.861706 17.6446 0.676395 17.5116 0.536913L17.5026 0.527912C17.4381 0.460059 17.3606 0.40603 17.2746 0.369109C17.1886 0.332189 17.0959 0.31315 17.0024 0.31315C16.9088 0.31315 16.8162 0.332189 16.7302 0.369109C16.6442 0.40603 16.5666 0.460059 16.5021 0.527912L9.00211 8.39991L1.50511 0.527914C1.44065 0.460061 1.36306 0.406031 1.27706 0.369111C1.19106 0.33219 1.09845 0.313151 1.00486 0.313151C0.911267 0.313151 0.818656 0.33219 0.732658 0.369111C0.646657 0.406031 0.569068 0.460061 0.504607 0.527914L0.495607 0.536914C0.36264 0.676397 0.288463 0.861707 0.288463 1.05441C0.288463 1.24712 0.36264 1.43243 0.495607 1.57191L8.46061 9.93141C8.53065 10.0049 8.6149 10.0635 8.70824 10.1035C8.80158 10.1434 8.90206 10.1641 9.00361 10.1641C9.10515 10.1641 9.20564 10.1434 9.29898 10.1035C9.39232 10.0635 9.47656 10.0049 9.54661 9.93141Z"
                      fill={leadStatus == "VERKAUFT" ? "white" : "black"}
                    />
                  </svg>
                ) : (
                  <svg
                    width="18"
                    height="11"
                    viewBox="0 0 18 11"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      d="M8.45339 1.06859L0.488393 9.42809C0.355426 9.56757 0.28125 9.75288 0.28125 9.94559C0.28125 10.1383 0.355426 10.3236 0.488393 10.4631L0.497393 10.4721C0.561853 10.5399 0.639443 10.594 0.725442 10.6309C0.811442 10.6678 0.904053 10.6869 0.997643 10.6869C1.09123 10.6869 1.18384 10.6678 1.26984 10.6309C1.35584 10.594 1.43343 10.5399 1.49789 10.4721L8.99789 2.60009L16.4949 10.4721C16.5594 10.5399 16.6369 10.594 16.7229 10.6309C16.8089 10.6678 16.9016 10.6869 16.9951 10.6869C17.0887 10.6869 17.1813 10.6678 17.2673 10.6309C17.3533 10.594 17.4309 10.5399 17.4954 10.4721L17.5044 10.4631C17.6374 10.3236 17.7115 10.1383 17.7115 9.94559C17.7115 9.75288 17.6374 9.56757 17.5044 9.42809L9.53939 1.06859C9.46935 0.99507 9.3851 0.936541 9.29176 0.89655C9.19842 0.856559 9.09794 0.835938 8.99639 0.835938C8.89485 0.835938 8.79436 0.856559 8.70102 0.89655C8.60768 0.936541 8.52344 0.99507 8.45339 1.06859Z"
                      fill={leadStatus == "VERKAUFT" ? "white" : "black"}
                    />
                  </svg>
                )}
              </div>
              {toggle && leadStatus !== "VERKAUFT" && (
                <div className="oppen-ticket-dropdown-scroll">
                  <div className="oppen-ticket-dropdown-main">
                    <div className="oppen-ticket-dropdown-content">
                      {status?.map((item, i) => {
                        return (
                          <p key={i} onClick={() => handleStatus(item)}>
                            {item}
                          </p>
                        );
                      })}
                    </div>
                  </div>
                </div>
              )}
            </div>
            <div className="VattelnFallLead-main-content">
              <div className="VattelnFallLead-main-content-one">
                <div>
                  <label>Vorname</label>
                  <input
                    type="text"
                    placeholder="Vorname"
                    name="name"
                    value={leadData?.name}
                    onChange={(e) => handleChangeInput(e)}
                  />
                </div>
                <div>
                  <label>Nachname</label>
                  <input
                    type="text"
                    placeholder="Nachname"
                    name="nachname"
                    value={leadData?.nachname}
                    onChange={(e) => handleChangeInput(e)}
                  />
                </div>
                <div>
                  <label>Telefon</label>
                  <input
                    type="text"
                    placeholder="Telefon"
                    name="telephon"
                    value={leadData?.telephon}
                    onChange={(e) => handleChangeInput(e)}
                  />
                </div>
                <div className="email-input-mobile">
                  <label>E-Mail</label>
                  <input
                    type="text"
                    placeholder="E-Mail"
                    name="email"
                    onChange={(e) => handleChangeInput(e)}
                    value={leadData?.email}
                  />
                </div>
              </div>
              <div className="VattelnFallLead-main-content-one">
                <div>
                  <label>Straße und Hausnummer</label>
                  <input
                    type="text"
                    placeholder="Straße und Hausnummer"
                    name="strabe"
                    onChange={(e) => handleChangeInput(e)}
                    value={leadData?.strabe}
                  />
                </div>
                <div className="postleitzahl-inputs-main">
                  <div>
                    <label>Postleitzahl</label>
                    <input
                      type="text"
                      placeholder="Postleitzahl"
                      name="pLZ"
                      onChange={(e) => handleChangeInput(e)}
                      value={leadData?.pLZ}
                    />
                  </div>
                  <div>
                    <label>Ort</label>
                    <input
                      type="text"
                      placeholder="Ort"
                      name="location"
                      onChange={(e) => handleChangeInput(e)}
                      value={leadData?.location}
                    />
                  </div>
                </div>
                <div className="email-input-web">
                  <label>E-Mail</label>
                  <input
                    type="text"
                    placeholder="E-Mail"
                    name="email"
                    onChange={(e) => handleChangeInput(e)}
                    value={leadData?.email}
                  />
                </div>
              </div>

              {Vattentype == "project-pv" && (
                <>
                  <h2>Informationen</h2>
                  <div className="VattelnFallLead-main-content-one">
                    <div className="postleitzahl-inputs-main">
                      <div>
                        <label>Haustyp</label>
                        <input
                          type="text"
                          placeholder="Haustyp"
                          name="housetype"
                          onChange={(e) => handleChangeInput(e)}
                          value={leadData?.housetype}
                        />
                      </div>
                      <div>
                        <label>Dachtyp</label>
                        <input
                          type="text"
                          placeholder="Dachtyp"
                          name="satteldach"
                          value={leadData?.satteldach}
                          onChange={(e) => handleChangeInput(e)}
                        />
                      </div>
                    </div>
                    <div>
                      <label>Stromverbrauch</label>
                      <input
                        type="text"
                        placeholder="Stromverbrauch"
                        name="power_consumption"
                        onChange={(e) => handleChangeInput(e)}
                        value={leadData?.power_consumption}
                      />
                    </div>
                  </div>
                  <div className="VattelnFallLead-main-content-one">
                    <div className="postleitzahl-inputs-main">
                      <div>
                        <label>Dachbelegung</label>
                        <input
                          type="text"
                          placeholder="Dachbelegung"
                          name="roofFelt"
                          onChange={(e) => handleChangeInput(e)}
                          value={leadData?.roofFelt}
                        />
                      </div>
                      <div>
                        <label>Störfaktoren</label>
                        <input
                          type="text"
                          placeholder="Störfaktoren"
                          name="schornstein"
                          onChange={(e) => handleChangeInput(e)}
                          value={leadData?.schornstein
                            ?.map((item) => item)
                            .join(",")}
                          disabled
                        />
                      </div>
                    </div>
                  </div>
                  <div className="zusatz-div-alignment-main">
                    <div>
                      <h2>Zusatz</h2>
                      <div className="zusatz-inputs-main">
                        <div>
                          <label>E-Auto</label>
                          <input
                            type="text"
                            placeholder="E-Auto"
                            name="aktuellkeinEAuto"
                            value={leadData?.aktuellkeinEAuto}
                            onChange={(e) => handleChangeInput(e)}
                          />
                        </div>
                        <div className="ort-input">
                          <label>Wärmepumpe</label>
                          <input
                            type="text"
                            placeholder="Wärmepumpe"
                            name="rooftype"
                            onChange={(e) => handleChangeInput(e)}
                            value={leadData?.rooftype}
                          />
                        </div>
                        <div>
                          <label>Erreichbarkeit</label>
                          <input
                            type="text"
                            placeholder="Erreichbarkeit"
                            name="accessibility"
                            value={leadData?.accessibility}
                            onChange={(e) => handleChangeInput(e)}
                          />
                        </div>
                      </div>
                    </div>
                  </div>
                </>
              )}
              {Vattentype == "project-wp" && (
                <>
                  <div style={{ display: "flex", gap: "20px" }}>
                    <h2>Checkliste Wärmepumpe</h2>
                    {/* <button
                      onClick={() => uploadNewFiled()}
                      className="new-up-btn"
                    >
                      Wp doc Upload
                    </button> */}
                  </div>
                  <div className="VattelnFallLead-main-content-one updated-input-design">
                    <div className="postleitzahl-inputs-main ">
                      <div>
                        <label>Gebäudeart</label>
                        <select
                          onChange={(e) => handleWpChange(e)}
                          name="Gebäudeart"
                          value={wpFields?.Gebäudeart}
                        >
                          <option value={""}>Auswählen</option>
                          <option value={"Einfamilienhaus"}>
                            Einfamilienhaus
                          </option>
                          <option value={"Reihenhaus"}>Reihenhaus</option>
                          <option value={"Doppelhaushälfte"}>
                            Doppelhaushälfte
                          </option>
                          <option value={"Mehrfamilienhaus"}>
                            Mehrfamilienhaus
                          </option>
                        </select>
                      </div>
                      <div>
                        <label>Fenster</label>
                        <select
                          onChange={(e) => handleWpChange(e)}
                          name="Fenster"
                          value={wpFields?.Fenster}
                        >
                          <option value={""}>Auswählen</option>
                          <option value={"1-fach verglast"}>
                            1-fach verglast
                          </option>
                          <option value={"2-fach verglast"}>
                            2-fach verglast
                          </option>
                          <option value={"3-fach verglast"}>
                            3-fach verglast
                          </option>
                        </select>
                      </div>
                    </div>
                    <div>
                      <label>Warmwasser</label>
                      <select
                        onChange={(e) => handleWpChange(e)}
                        name="Warmwasser"
                        value={wpFields?.Warmwasser}
                      >
                        <option value={""}>Auswählen</option>
                        <option value={"Mit der Heizung (Zentral)"}>
                          Mit der Heizung (Zentral)
                        </option>
                        <option value={"Mit Durchlauferhitzer (Dezentral)"}>
                          Mit Durchlauferhitzer (Dezentral)
                        </option>
                      </select>
                    </div>
                    <div>
                      <label>Aktuelle Heizung</label>
                      <select
                        onChange={(e) => handleWpChange(e)}
                        name="AktuelleHeizung"
                        value={wpFields?.AktuelleHeizung}
                      >
                        <option value={""}>Auswählen</option>
                        <option value={"Erdgas"}>Erdgas</option>
                        <option value={"Fernwärme"}>Fernwärme</option>
                        <option value={"Heizöl"}>Heizöl</option>
                        <option value={"Holzpellets"}>Holzpellets</option>
                      </select>
                    </div>
                  </div>
                  <div className="VattelnFallLead-main-content-one updated-input-design">
                    <div className="postleitzahl-inputs-main">
                      <div>
                        <label>Warmwasserspeicher vorhanden?</label>
                        <select
                          onChange={(e) => handleWpChange(e)}
                          name="Warmwasserspeichervorhanden"
                          value={wpFields?.Warmwasserspeichervorhanden}
                        >
                          <option value={""}>Auswählen</option>
                          <option value={"Ja"}>Ja</option>
                          <option value={"Nein"}>Nein</option>
                        </select>
                      </div>
                      <div>
                        <label>Volumen</label>
                        <input
                          type="text"
                          placeholder="Volumen"
                          name="Volumen"
                          onChange={(e) => handleChangeInput(e)}
                          value={wpFields?.Volumen}
                          disabled={
                            !wpFields?.Warmwasserspeichervorhanden ||
                            wpFields?.Warmwasserspeichervorhanden == "Nein"
                          }
                        />
                      </div>
                    </div>
                    <div>
                      <label>Heizsystem</label>
                      <select
                        onChange={(e) => handleWpChange(e)}
                        name="Heizsystem"
                        value={wpFields?.Heizsystem}
                      >
                        <option value={""}>Auswählen</option>
                        <option value={"Nur Heizkörper"}>Nur Heizkörper</option>
                        <option value={"Nur Fußbodenheizung"}>
                          Nur Fußbodenheizung
                        </option>
                        <option
                          value={"Heizkörper + Fußbodenheizung etagenweit"}
                        >
                          Heizkörper + Fußbodenheizung etagenweit
                        </option>
                        <option
                          value={"Heizkörper + Fußbodenheizung Einzelräume"}
                        >
                          Heizkörper + Fußbodenheizung Einzelräume
                        </option>
                      </select>
                    </div>
                    <div>
                      <label>Standort der aktuellen Heizung</label>
                      <select
                        onChange={(e) => handleWpChange(e)}
                        name="StandortderaktuellenHeizung"
                        value={wpFields?.StandortderaktuellenHeizung}
                      >
                        <option value={""}>Auswählen</option>
                        <option value={"Dachgeschoss"}>Dachgeschoss</option>
                        <option value={"Hauswirtschaftsraum"}>
                          Hauswirtschaftsraum
                        </option>
                        <option value={"Keller"}>Keller</option>
                      </select>
                    </div>
                  </div>
                  <div className="zusatz-div-alignment-mains">
                    <div>
                      <div className="zusatz-inputs-main">
                        <div>
                          <label>Deckenhöhe </label>
                          <input
                            type="text"
                            placeholder="Deckenhöhe"
                            name="Deckenhöhe"
                            value={wpFields?.Deckenhöhe}
                            onChange={(e) => handleWpChange(e)}
                          />
                        </div>
                        <div className="ort-input">
                          <label>Einbaujahr</label>
                          <input
                            type="text"
                            placeholder="Einbaujahr"
                            name="Einbaujahr"
                            onChange={(e) => handleWpChange(e)}
                            value={wpFields?.Einbaujahr}
                          />
                        </div>
                        <div>
                          <label>Typ/Hersteller</label>
                          <input
                            type="text"
                            placeholder="Typ/Hersteller"
                            name="TypHersteller"
                            value={wpFields?.TypHersteller}
                            onChange={(e) => handleWpChange(e)}
                          />
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="zusatz-div-alignment-mains">
                    <div>
                      <div className="zusatz-inputs-main">
                        <div>
                          <label>Standort der neuen Heizung</label>
                          <input
                            type="text"
                            placeholder="StandortderneuenHeizung"
                            name="StandortderneuenHeizung"
                            value={wpFields?.StandortderneuenHeizung}
                            onChange={(e) => handleWpChange(e)}
                          />
                        </div>
                        <div className="ort-input">
                          <label>Entsorgung erwünscht</label>
                          <select
                            onChange={(e) => handleWpChange(e)}
                            name="Entsorgungerwünscht"
                            value={wpFields?.Entsorgungerwünscht}
                          >
                            <option value={""}>Auswählen</option>
                            <option value={"Ja"}>Ja</option>
                            <option value={"Nein"}>Nein</option>
                          </select>
                        </div>
                        <div>
                          <label>Aktueller Verbrauch Vorjahr</label>
                          <input
                            type="text"
                            placeholder="Aktueller Verbrauch Vorjahr"
                            name="AktuellerVerbrauchVorjahr"
                            value={wpFields?.AktuellerVerbrauchVorjahr}
                            onChange={(e) => handleWpChange(e)}
                          />
                        </div>
                        <div>
                          <label>Gesamtwohlfläche in m²</label>
                          <input
                            type="text"
                            placeholder="Gesamtwohlfläche in m²"
                            name="Gesamtwohlflächeinm"
                            value={wpFields?.Gesamtwohlflächeinm}
                            onChange={(e) => handleWpChange(e)}
                          />
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="zusatz-div-alignment-mains">
                    <div>
                      <div className="zusatz-inputs-main">
                        <div>
                          <label>Davon beheizt in m²</label>
                          <input
                            type="text"
                            placeholder="Davon beheizt in m²"
                            name="Davonbeheiztinm"
                            value={wpFields?.Davonbeheiztinm}
                            onChange={(e) => handleWpChange(e)}
                          />
                        </div>
                        <div className="ort-input">
                          <label>Personen im Haushalt</label>
                          <input
                            type="text"
                            placeholder="Personen im Haushalt"
                            name="PersonenimHaushalt"
                            value={wpFields?.PersonenimHaushalt}
                            onChange={(e) => handleWpChange(e)}
                          />
                        </div>
                        <div>
                          <label>Anzahl Bäder</label>
                          <input
                            type="text"
                            placeholder="Anzahl Bäder"
                            name="AnzahlBäder"
                            value={wpFields?.AnzahlBäder}
                            onChange={(e) => handleWpChange(e)}
                          />
                        </div>
                        <div>
                          <label>Anzahl Duschen</label>
                          <input
                            type="text"
                            placeholder="Anzahl Duschen"
                            name="AnzahlDuschen"
                            value={wpFields?.AnzahlDuschen}
                            onChange={(e) => handleWpChange(e)}
                          />
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="zusatz-div-alignment-mains">
                    <div>
                      <div className="zusatz-inputs-main">
                        <div>
                          <label>Leitungsweg innen</label>
                          <input
                            type="text"
                            placeholder="Leitungsweg innen"
                            name="Leitungsweginnen"
                            value={wpFields?.Leitungsweginnen}
                            onChange={(e) => handleWpChange(e)}
                          />
                        </div>
                        <div className="ort-input">
                          <label>Leitungsweg außen</label>
                          <input
                            type="text"
                            placeholder="Leitungsweg außen"
                            name="Leitungswegaußen"
                            value={wpFields?.Leitungswegaußen}
                            onChange={(e) => handleWpChange(e)}
                          />
                        </div>
                        <div>
                          <label>Anzahl Haushalte</label>
                          <input
                            type="text"
                            placeholder="Anzahl Haushalter"
                            name="AnzahlHaushalte"
                            value={wpFields?.AnzahlHaushalte}
                            onChange={(e) => handleWpChange(e)}
                          />
                        </div>
                        <div>
                          <label>Anzahl Heizkörper</label>
                          <input
                            type="text"
                            placeholder="Anzahl Heizkörper"
                            name="AnzahlHeizkörper"
                            value={wpFields?.AnzahlHeizkörper}
                            onChange={(e) => handleWpChange(e)}
                          />
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="zusatz-div-alignment-mains-pp">
                    <div>
                      <div className="zusatz-inputs-main">
                        <div>
                          <label>Anzahl Badewannen</label>
                          <input
                            type="text"
                            placeholder="Anzahl Badewannen"
                            name="AnzahlBadewannen"
                            value={wpFields?.AnzahlBadewannen}
                            onChange={(e) => handleWpChange(e)}
                          />
                        </div>
                        <div>
                          <label>Grundriss </label>
                          <div className="main-relative">
                            <input
                              type="file"
                              name="Grundriss"
                              multiple
                              onChange={(e) => uploadImageFiled(e)}
                            />

                            <div className="upload-icon-size-change">
                              <UploadIconR />
                            </div>
                          </div>
                          <div className="all-upload-data-details">
                            {wpFields?.Grundriss?.map((item, index) => {
                              return (
                                <div className="upload-data-grid">
                                  <p>
                                    <p>{item}</p>
                                  </p>
                                  <div className="two-icon-alignment">
                                    <EyeIcon
                                      onClick={() => window.open(item)}
                                    />
                                    <CloseIcon
                                      onClick={() =>
                                        removeFile("Grundriss", index)
                                      }
                                    />
                                  </div>
                                </div>
                              );
                            })}
                          </div>
                        </div>

                        <div>
                          <label>Heizung frontal </label>
                          <div className="main-relative">
                            <input
                              type="file"
                              name="Heizungfrontal"
                              multiple
                              onChange={(e) => uploadImageFiled(e)}
                            />

                            <div className="upload-icon-size-change">
                              <UploadIconR />
                            </div>
                          </div>
                          <div className="all-upload-data-details">
                            {wpFields?.Heizungfrontal?.map((item, index) => {
                              return (
                                <div className="upload-data-grid">
                                  <p>{item}</p>
                                  <div className="two-icon-alignment">
                                    <EyeIcon
                                      onClick={() => window.open(item)}
                                    />
                                    <CloseIcon
                                      onClick={() =>
                                        removeFile("Heizungfrontal", index)
                                      }
                                    />
                                  </div>
                                </div>
                              );
                            })}
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="zusatz-div-alignment-mains-pp">
                    <div>
                      <div className="zusatz-inputs-main">
                        <div>
                          <label>Aufstellort Außeneinheit </label>
                          <div className="main-relative">
                            <input
                              type="file"
                              name="AufstellortAußeneinheit"
                              multiple
                              onChange={(e) => uploadImageFiled(e)}
                            />

                            <div className="upload-icon-size-change">
                              <UploadIconR />
                            </div>
                          </div>
                          <div className="all-upload-data-details">
                            {wpFields?.AufstellortAußeneinheit?.map(
                              (item, index) => {
                                return (
                                  <div className="upload-data-grid">
                                    <p>{item}</p>
                                    <div className="two-icon-alignment">
                                      <EyeIcon
                                        onClick={() => window.open(item)}
                                      />
                                      <CloseIcon
                                        onClick={() =>
                                          removeFile(
                                            "AufstellortAußeneinheit",
                                            index
                                          )
                                        }
                                      />
                                    </div>
                                  </div>
                                );
                              }
                            )}
                          </div>
                        </div>
                        <div>
                          <label>Zählerschrank geöffnet </label>
                          <div className="main-relative">
                            <input
                              type="file"
                              name="Zählerschrankgeöffnet"
                              multiple
                              onChange={(e) => uploadImageFiled(e)}
                            />

                            <div className="upload-icon-size-change">
                              <UploadIconR />
                            </div>
                          </div>
                          <div className="all-upload-data-details">
                            {wpFields?.Zählerschrankgeöffnet?.map(
                              (item, index) => {
                                return (
                                  <div className="upload-data-grid">
                                    <p>{item}</p>
                                    <div className="two-icon-alignment">
                                      <EyeIcon
                                        onClick={() => window.open(item)}
                                      />
                                      <CloseIcon
                                        onClick={() =>
                                          removeFile(
                                            "Zählerschrankgeöffnet",
                                            index
                                          )
                                        }
                                      />
                                    </div>
                                  </div>
                                );
                              }
                            )}
                          </div>
                        </div>
                        <div>
                          <label>Aufstellort Inneneinheit </label>
                          <div className="main-relative">
                            <input
                              type="file"
                              name="AufstellortInneneinheit"
                              multiple
                              onChange={(e) => uploadImageFiled(e)}
                            />

                            <div className="upload-icon-size-change">
                              <UploadIconR />
                            </div>
                          </div>
                          <div className="all-upload-data-details">
                            {wpFields?.AufstellortInneneinheit?.map(
                              (item, index) => {
                                return (
                                  <div className="upload-data-grid">
                                    <p>{item}</p>
                                    <div className="two-icon-alignment">
                                      <EyeIcon
                                        onClick={() => window.open(item)}
                                      />
                                      <CloseIcon
                                        onClick={() =>
                                          removeFile(
                                            "AufstellortInneneinheit",
                                            index
                                          )
                                        }
                                      />
                                    </div>
                                  </div>
                                );
                              }
                            )}
                          </div>
                        </div>
                        <div>
                          <label>Fußbodenheizung Verteiler </label>
                          <div className="main-relative">
                            <input
                              type="file"
                              name="Verteiler"
                              multiple
                              onChange={(e) => uploadImageFiled(e)}
                            />

                            <div className="upload-icon-size-change">
                              <UploadIconR />
                            </div>
                          </div>
                          <div className="all-upload-data-details">
                            {wpFields?.Verteiler?.map((item, index) => {
                              return (
                                <div className="upload-data-grid">
                                  <p>{item}</p>
                                  <div className="two-icon-alignment">
                                    <EyeIcon
                                      onClick={() => window.open(item)}
                                    />
                                    <CloseIcon
                                      onClick={() =>
                                        removeFile("Verteiler", index)
                                      }
                                    />
                                  </div>
                                </div>
                              );
                            })}
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </>
              )}

              <div>
                <div className="zusatzinformationen-div-main">
                  <h2>Zusatzinformationen zu Projekt</h2>
                  <p>{`Notizen zum Kunden von ${leadData?.benutzername}`}</p>
                  <textarea
                    placeholder="Notizen zum Kunden"
                    value={notizen}
                    name="notizen"
                    onChange={(e) => setNotizen(e.target.value)}
                  ></textarea>

                  {notizenArray?.map((item) => {
                    const localTime = moment(item.updatedAt)
                      .local()
                      .format("YYYY-MM-DD HH:mm:ss");
                    const date = moment(localTime);
                    const formattedDate = date.format("DD.MM.YYYY");
                    const formattedTime = date.format("HH:mm");
                    const result = `${formattedDate} um ${formattedTime} Uhr`;
                    return (
                      <>
                        <p style={{ marginTop: "5px" }}>
                          {"    "}
                          {item?.user_name} - {result}{" "}
                          <i
                            class="fa-solid fa-circle-info"
                            style={{
                              cursor: "pointer",
                              marginLeft: "2px",
                            }}
                            onClick={() =>
                              handleLeadsModels(item, "show", "notizen")
                            }
                          ></i>{" "}
                          <svg
                            onClick={() =>
                              handleLeadsModels(item, "update", "notizen")
                            }
                            style={{ cursor: "pointer", marginBottom: "2px" }}
                            width="16"
                            height="16"
                            viewBox="0 0 16 16"
                            fill="none"
                            xmlns="http://www.w3.org/2000/svg"
                          >
                            <path
                              d="M-0.0078125 13.2069V15.4998C-0.0078125 15.6325 0.044866 15.7596 0.138634 15.8534C0.232402 15.9472 0.359579 15.9998 0.492188 15.9998H2.79019C2.92254 15.9998 3.04948 15.9473 3.14319 15.8538L12.5912 6.40585L9.59119 3.40585L0.139188 12.8538C0.0453521 12.9474 -0.00751912 13.0744 -0.0078125 13.2069ZM10.8292 2.16685L13.8292 5.16685L15.2892 3.70685C15.4767 3.51932 15.582 3.26501 15.582 2.99985C15.582 2.73468 15.4767 2.48038 15.2892 2.29285L13.7042 0.706849C13.5167 0.519378 13.2624 0.414062 12.9972 0.414062C12.732 0.414062 12.4777 0.519378 12.2902 0.706849L10.8292 2.16685Z"
                              fill="black"
                            />
                          </svg>
                        </p>
                      </>
                    );
                  })}
                </div>
                <div className="zusatzinformationen-div-main">
                  <p>Eigene Notizen zum Kunden</p>
                  <textarea
                    placeholder="Notizen zum Kunden"
                    name="ownMessage"
                    value={ownMessage}
                    onChange={(e) => handleInput(e)}
                  ></textarea>

                  {leadNotesArray?.map((item) => {
                    const localTime = moment(item.updatedAt)
                      .local()
                      .format("YYYY-MM-DD HH:mm:ss");
                    const date = moment(localTime);
                    const formattedDate = date.format("DD.MM.YYYY");
                    const formattedTime = date.format("HH:mm");
                    const result = `${formattedDate} um ${formattedTime} Uhr`;
                    return (
                      <>
                        <p style={{ marginTop: "5px" }}>
                          {"    "}
                          {item?.user_name} - {result}{" "}
                          <i
                            class="fa-solid fa-circle-info"
                            style={{
                              cursor: "pointer",
                              marginLeft: "2px",
                            }}
                            onClick={() =>
                              handleLeadsModels(item, "show", "leadNotes")
                            }
                          ></i>{" "}
                          <svg
                            onClick={() =>
                              handleLeadsModels(item, "update", "leadNotes")
                            }
                            style={{ cursor: "pointer", marginBottom: "2px" }}
                            width="16"
                            height="16"
                            viewBox="0 0 16 16"
                            fill="none"
                            xmlns="http://www.w3.org/2000/svg"
                          >
                            <path
                              d="M-0.0078125 13.2069V15.4998C-0.0078125 15.6325 0.044866 15.7596 0.138634 15.8534C0.232402 15.9472 0.359579 15.9998 0.492188 15.9998H2.79019C2.92254 15.9998 3.04948 15.9473 3.14319 15.8538L12.5912 6.40585L9.59119 3.40585L0.139188 12.8538C0.0453521 12.9474 -0.00751912 13.0744 -0.0078125 13.2069ZM10.8292 2.16685L13.8292 5.16685L15.2892 3.70685C15.4767 3.51932 15.582 3.26501 15.582 2.99985C15.582 2.73468 15.4767 2.48038 15.2892 2.29285L13.7042 0.706849C13.5167 0.519378 13.2624 0.414062 12.9972 0.414062C12.732 0.414062 12.4777 0.519378 12.2902 0.706849L10.8292 2.16685Z"
                              fill="black"
                            />
                          </svg>
                        </p>
                      </>
                    );
                  })}
                </div>
                <div className="lead-peragraph-main">
                  <p>
                    {`Lead generiert von "${
                      leadData?.benutzername
                    }" am ${moment(leadData?.createdAt).format("DD/MM/YYYY")}`}
                  </p>
                </div>
              </div>
              {leadStatus != "offen" && (
                <div>
                  <div className="notizen-plus-div-sc">
                    <p>Terminhistorie</p>
                    {noteModel && (
                      <div className="notizen-plus-div-sc-model-background">
                        <div className="notizen-plus-div-sc-model-main">
                          <div className="notizen-plus-div-sc-model-main-icon">
                            <label>Notizen zum Termin</label>
                            <i
                              class="fa-sharp fa-solid fa-xmark"
                              aria-hidden="true"
                              onClick={() => {
                                setNoteModel(!noteModel);
                                setItShow("");
                              }}
                              style={{ cursor: "pointer" }}
                            ></i>
                          </div>
                          <textarea
                            placeholder="Notizen zum Termin..."
                            name="notes"
                            value={note?.notes}
                            onChange={(e) =>
                              itShow !== "show" && handleModelsChange(e)
                            }
                          ></textarea>

                          {itShow !== "show" && (
                            <div className="footer-button-alignment">
                              <button onClick={() => updateNotes()}>
                                {loading ? (
                                  <TailSpin
                                    color="#FFF"
                                    height={10}
                                    width={10}
                                  />
                                ) : (
                                  "Aktualisieren"
                                )}
                              </button>
                            </div>
                          )}
                        </div>
                      </div>
                    )}
                  </div>
                  {appoinmentData?.map((item, i) => {
                    const itemData = item?.appointmentDate;
                    const localTime = moment
                      .utc(itemData)
                      .local()
                      .format("YYYY-MM-DD HH:mm:ss");
                    const date = moment(localTime);
                    const formattedDate = date.format("DD.MM.YYYY");
                    const formattedTime = date.format("HH:mm");
                    const result = `${formattedDate} um ${formattedTime} Uhr`;
                    const isPast = date.isBefore(moment(), "minute");
                    return (
                      <div key={i} className="notizen-plus-div-sc">
                        <div className="notizen-plus-div-sc-alig">
                          <p>
                            Notizen zum Termin{" "}
                            {item?.notes ? (
                              <>
                                <i
                                  class="fa-solid fa-circle-info"
                                  style={{ cursor: "pointer" }}
                                  onClick={() => handleModels(item)}
                                ></i>
                              </>
                            ) : (
                              leadStatus !== "VERKAUFT" && (
                                <svg
                                  onClick={() => handleModels(item)}
                                  style={{ cursor: "pointer" }}
                                  width="14"
                                  height="14"
                                  viewBox="0 0 14 14"
                                  fill="none"
                                  xmlns="http://www.w3.org/2000/svg"
                                >
                                  <path
                                    d="M13 7.99609H8V12.9961C8 13.2613 7.89464 13.5157 7.70711 13.7032C7.51957 13.8907 7.26522 13.9961 7 13.9961C6.73478 13.9961 6.48043 13.8907 6.29289 13.7032C6.10536 13.5157 6 13.2613 6 12.9961V7.99609H1C0.734784 7.99609 0.48043 7.89074 0.292893 7.7032C0.105357 7.51566 0 7.26131 0 6.99609C0 6.73088 0.105357 6.47652 0.292893 6.28899C0.48043 6.10145 0.734784 5.99609 1 5.99609H6V0.996094C6 0.730877 6.10536 0.476523 6.29289 0.288987C6.48043 0.10145 6.73478 -0.00390625 7 -0.00390625C7.26522 -0.00390625 7.51957 0.10145 7.70711 0.288987C7.89464 0.476523 8 0.730877 8 0.996094V5.99609H13C13.2652 5.99609 13.5196 6.10145 13.7071 6.28899C13.8946 6.47652 14 6.73088 14 6.99609C14 7.26131 13.8946 7.51566 13.7071 7.7032C13.5196 7.89074 13.2652 7.99609 13 7.99609Z"
                                    fill="#4D4D4D"
                                  />
                                </svg>
                              )
                            )}
                          </p>
                          <p>{item?.notes}</p>
                          <div className="svg-ic-align">
                            <span>{result}</span>
                            {!isPast && (
                              <svg
                                onClick={() => updateMeeting(item)}
                                style={{ cursor: "pointer" }}
                                width="16"
                                height="16"
                                viewBox="0 0 16 16"
                                fill="none"
                                xmlns="http://www.w3.org/2000/svg"
                              >
                                <path
                                  d="M-0.0078125 13.2069V15.4998C-0.0078125 15.6325 0.044866 15.7596 0.138634 15.8534C0.232402 15.9472 0.359579 15.9998 0.492188 15.9998H2.79019C2.92254 15.9998 3.04948 15.9473 3.14319 15.8538L12.5912 6.40585L9.59119 3.40585L0.139188 12.8538C0.0453521 12.9474 -0.00751912 13.0744 -0.0078125 13.2069ZM10.8292 2.16685L13.8292 5.16685L15.2892 3.70685C15.4767 3.51932 15.582 3.26501 15.582 2.99985C15.582 2.73468 15.4767 2.48038 15.2892 2.29285L13.7042 0.706849C13.5167 0.519378 13.2624 0.414062 12.9972 0.414062C12.732 0.414062 12.4777 0.519378 12.2902 0.706849L10.8292 2.16685Z"
                                  fill="black"
                                />
                              </svg>
                            )}
                          </div>
                        </div>
                      </div>
                    );
                  })}
                </div>
              )}
            </div>
            <div style={{ margin: "0 0 5px 0", display: "flex", gap: "25px" }}>
              <button
                onClick={() => setNewDocModel(true)}
                className="new-up-btn-mob"
              >
                Angebot upload
              </button>
              {leadData?.["vattenfallDocument"] && (
                <div className="all-upload-data-alignmentss">
                  <div className="box-design-style">
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      viewBox="0 0 384 512"
                    >
                      <path d="M0 64C0 28.7 28.7 0 64 0H224V128c0 17.7 14.3 32 32 32H384V448c0 35.3-28.7 64-64 64H64c-35.3 0-64-28.7-64-64V64zm384 64H256V0L384 128z" />
                    </svg>
                    <span>Dokumentieren</span>
                    <div className="two-icon-alignment">
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        viewBox="0 0 512 512"
                        onClick={(e) => downloadS3File()}
                      >
                        <path d="M288 32c0-17.7-14.3-32-32-32s-32 14.3-32 32V274.7l-73.4-73.4c-12.5-12.5-32.8-12.5-45.3 0s-12.5 32.8 0 45.3l128 128c12.5 12.5 32.8 12.5 45.3 0l128-128c12.5-12.5 12.5-32.8 0-45.3s-32.8-12.5-45.3 0L288 274.7V32zM64 352c-35.3 0-64 28.7-64 64v32c0 35.3 28.7 64 64 64H448c35.3 0 64-28.7 64-64V416c0-35.3-28.7-64-64-64H346.5l-45.3 45.3c-25 25-65.5 25-90.5 0L165.5 352H64zm368 56a24 24 0 1 1 0 48 24 24 0 1 1 0-48z" />
                      </svg>
                    </div>
                  </div>
                </div>
              )}
            </div>

            <div style={{ display: "flex", gap: "20px" }}>
              <div className="terminieren-button-main">
                {leadStatus == "offen" || appoinmentData?.length == 0 ? (
                  <button
                    onClick={() => handleCalendar()}
                    disabled={
                      leadStatus == "VERKAUFT" || leadStatus == "STORNO"
                    }
                  >
                    Terminieren
                  </button>
                ) : results?.includes(true) ? (
                  <button
                    onClick={() => handleCalendar()}
                    disabled={
                      leadStatus == "VERKAUFT" || leadStatus == "STORNO"
                    }
                  >
                    Weiteren Termin erstellen
                  </button>
                ) : (
                  <button
                    onClick={() => handleCalendar()}
                    disabled={
                      leadStatus == "VERKAUFT" || leadStatus == "STORNO"
                    }
                  >
                    Weiteren Termin erstellen
                  </button>
                )}
              </div>
              <div className="terminieren-button-main">
                <button
                  onClick={handleUserDetails}
                  disabled={leadStatus == "VERKAUFT" || leadStatus == "STORNO"}
                >
                  {loading ? (
                    <TailSpin color="#FFF" height={25} width={25} />
                  ) : (
                    "Speichern"
                  )}
                </button>
              </div>
            </div>
          </div>
        </div>
      ) : (
        <CustomCalendar
          ownMessage={ownMessage}
          setModal={setModal}
          modal={modal}
          leadStatus={leadStatus == "offen" ? "TERMINIERT (1)" : leadStatus}
          leadData={leadData}
          meetingDate={meetingDate}
          appoinmentData={appoinmentData}
          type={type}
          leadNotesArray={leadNotesArray}
        />
      )}

      {leadNotesModel && (
        <LeadNotesModel
          singleLeadNote={singleLeadNote}
          setLeadNotesModel={setLeadNotesModel}
          setLeadNotesArray={setLeadNotesArray}
          leadNotesArray={leadNotesArray}
          leadShow={leadShow}
          setLeadShow={setLeadShow}
          setSingleLeadNote={setSingleLeadNote}
          notizenArray={notizenArray}
          id={id}
          getDataById={getDataById}
          setModalType={setModalType}
          modalType={modalType}
        />
      )}

      {dropModel && (
        <>
          <div className="dropdown-modal">
            <div className="dropdown-modal-md">
              <div className="header-align">
                <div className="headfer-design">
                  <p>Stornogrund </p>
                </div>
                <div
                  className="close-icon"
                  onClick={() => {
                    setDropMopdel(!dropModel);
                    setReason("");
                    setError("");
                  }}
                >
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="20px"
                    height="20px"
                    viewBox="0 0 24 24"
                    fill="none"
                  >
                    <path
                      d="M20.7457 3.32851C20.3552 2.93798 19.722 2.93798 19.3315 3.32851L12.0371 10.6229L4.74275 3.32851C4.35223 2.93798 3.71906 2.93798 3.32854 3.32851C2.93801 3.71903 2.93801 4.3522 3.32854 4.74272L10.6229 12.0371L3.32856 19.3314C2.93803 19.722 2.93803 20.3551 3.32856 20.7457C3.71908 21.1362 4.35225 21.1362 4.74277 20.7457L12.0371 13.4513L19.3315 20.7457C19.722 21.1362 20.3552 21.1362 20.7457 20.7457C21.1362 20.3551 21.1362 19.722 20.7457 19.3315L13.4513 12.0371L20.7457 4.74272C21.1362 4.3522 21.1362 3.71903 20.7457 3.32851Z"
                      fill="#000"
                    />
                  </svg>
                </div>
              </div>
              <div className="body-part-data">
                <label>
                  Stornogrund <span style={{ color: "red" }}>*</span>
                </label>
                <select name="reason" onChange={(e) => handleChange(e)}>
                  <option value={""}>Stronogrund auswählen</option>
                  {resonData?.map((item) => {
                    return <option value={item?.reason}>{item?.reason}</option>;
                  })}
                </select>

                <span className="errorclass">{error?.reason}</span>
              </div>

              <div className="modl-dfot-design">
                <button onClick={updateReason}>
                  {laoding ? (
                    <TailSpin color="#FFF" height={15} width={15} />
                  ) : (
                    "einreichen"
                  )}
                </button>
              </div>
            </div>
          </div>
        </>
      )}

      {docModel && (
        <div className="dropdown-modalsppop">
          <div className="dropdown-modal-mds">
            <div className="header-aligns">
              <div className="headfer-designs">
                <p>Projekte wp Files</p>
              </div>
              <div
                className="close-icons"
                onClick={() => {
                  setDocModel(false);
                }}
              >
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="20px"
                  height="20px"
                  viewBox="0 0 24 24"
                  fill="none"
                >
                  <path
                    d="M20.7457 3.32851C20.3552 2.93798 19.722 2.93798 19.3315 3.32851L12.0371 10.6229L4.74275 3.32851C4.35223 2.93798 3.71906 2.93798 3.32854 3.32851C2.93801 3.71903 2.93801 4.3522 3.32854 4.74272L10.6229 12.0371L3.32856 19.3314C2.93803 19.722 2.93803 20.3551 3.32856 20.7457C3.71908 21.1362 4.35225 21.1362 4.74277 20.7457L12.0371 13.4513L19.3315 20.7457C19.722 21.1362 20.3552 21.1362 20.7457 20.7457C21.1362 20.3551 21.1362 19.722 20.7457 19.3315L13.4513 12.0371L20.7457 4.74272C21.1362 4.3522 21.1362 3.71903 20.7457 3.32851Z"
                    fill="#000"
                  />
                </svg>
              </div>
            </div>
            <div className="body-part-datas">
              {FileNameArray?.map((item, index) => {
                return (
                  <div
                    style={{
                      display: "flex",
                      gap: "10px",
                      alignItems: "center",
                      marginBottom: "5px",
                    }}
                  >
                    <p>
                      {item?.text}
                      <span style={{ color: "red" }}>*</span>
                    </p>
                    <div
                      className="file-upload"
                      style={{ marginBottom: "5px" }}
                    >
                      {item?.loader ? (
                        <TailSpin color="#006037" height={20} width={20} />
                      ) : (
                        <>
                          {leadData?.[item?.name] ? (
                            <div
                              style={{
                                display: "flex",
                                gap: "10px",
                              }}
                            >
                              <GreenCheckIcon />
                              <div style={{ paddingBottom: "8px" }}>
                                <input
                                  type="file"
                                  name={item?.name}
                                  onChange={(e) => handleChangeImage(e, item)}
                                />
                                <EditIconSvg />
                              </div>
                            </div>
                          ) : (
                            <>
                              <input
                                type="file"
                                name={item?.name}
                                onChange={(e) => handleChangeImage(e, item)}
                              />
                              <UploadIconR />
                            </>
                          )}
                        </>
                      )}
                    </div>
                  </div>
                );
              })}

              <div className="react-date-cus">
                <div
                  style={{
                    display: "flex",
                    gap: "10px",
                    alignItems: "center",
                    paddingBottom: "10px",
                  }}
                >
                  <p style={{ marginBottom: "0px" }}>Entsorgung:</p>
                  <div
                    style={{
                      display: "flex",
                      gap: "10px",
                      alignItems: "center",
                    }}
                  >
                    <div
                      style={{
                        display: "flex",
                        gap: "5px",
                        alignItems: "center",
                      }}
                    >
                      <input
                        type="checkbox"
                        style={{ accentColor: "#006037" }}
                        checked={leadData?.Ja || fileCheckBox == "Ja"}
                        id="check1"
                        onChange={() => setFileCheckBox("Ja")}
                      />
                      <label style={{ marginBottom: "0px" }} htmlFor="check1">
                        Ja
                      </label>
                    </div>
                    <div
                      style={{
                        display: "flex",
                        gap: "5px",
                        alignItems: "center",
                      }}
                    >
                      <input
                        type="checkbox"
                        style={{ accentColor: "#006037" }}
                        checked={fileCheckBox == "Nein"}
                        id="check2"
                        onChange={() => {
                          setFileCheckBox("Nein");
                        }}
                      />
                      <label style={{ marginBottom: "0px" }} htmlFor="check2">
                        Nein
                      </label>
                    </div>
                  </div>
                </div>
                {(fileCheckBox == "Ja" || leadData.Ja) && (
                  <textarea
                    placeholder="you can write here....."
                    className="dateaaaaaaaaaa"
                    style={{ height: "auto" }}
                    value={leadData?.Ja}
                    onChange={(e) =>
                      setLeadData({ ...leadData, Ja: e.target.value })
                    }
                  />
                )}
              </div>
            </div>

            <div
              className="modl-dfot-designs"
              style={{ display: "flex", gap: "10px" }}
            >
              <button onClick={handleOnClick}>
                {/* {loadings ? (
                  <TailSpin color="#FFF" height={15} width={15} />
                ) : (
                  "speichern"
                )} */}
                save
              </button>
            </div>
          </div>
        </div>
      )}

      {newDocModel && (
        <div className="dropdown-modalsppop-new">
          <div className="dropdown-modal-mds-new">
            <div className="header-aligns">
              <div className="headfer-designs">
                <p>Upload Doc</p>
              </div>
              <div
                className="close-icons"
                onClick={() => {
                  setNewDocModel(false);
                }}
              >
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="20px"
                  height="20px"
                  viewBox="0 0 24 24"
                  fill="none"
                >
                  <path
                    d="M20.7457 3.32851C20.3552 2.93798 19.722 2.93798 19.3315 3.32851L12.0371 10.6229L4.74275 3.32851C4.35223 2.93798 3.71906 2.93798 3.32854 3.32851C2.93801 3.71903 2.93801 4.3522 3.32854 4.74272L10.6229 12.0371L3.32856 19.3314C2.93803 19.722 2.93803 20.3551 3.32856 20.7457C3.71908 21.1362 4.35225 21.1362 4.74277 20.7457L12.0371 13.4513L19.3315 20.7457C19.722 21.1362 20.3552 21.1362 20.7457 20.7457C21.1362 20.3551 21.1362 19.722 20.7457 19.3315L13.4513 12.0371L20.7457 4.74272C21.1362 4.3522 21.1362 3.71903 20.7457 3.32851Z"
                    fill="#000"
                  />
                </svg>
              </div>
            </div>
            <div className="body-part-datas">
              <div
                style={{
                  display: "flex",
                  gap: "10px",
                  alignItems: "center",
                  marginBottom: "5px",
                }}
              >
                <p style={{ fontSize: "16px", fontWeight: "600" }}>
                  Dokumentieren
                  <span style={{ color: "red" }}>*</span>
                </p>
                <div className="file-upload" style={{ marginBottom: "5px" }}>
                  {docLoader ? (
                    <TailSpin color="#006037" height={20} width={20} />
                  ) : (
                    <>
                      {leadData?.["vattenfallDocument"] ? (
                        <div
                          style={{
                            display: "flex",
                            gap: "10px",
                            padding: "20px 0",
                          }}
                        >
                          <GreenCheckIcon />
                          <div
                            style={{
                              paddingBottom: "8px",
                              display: "flex",
                            }}
                          >
                            <input
                              type="file"
                              onChange={(e) => handleUploadDoc(e)}
                            />
                            <div style={{ display: "flex", gap: "10px" }}>
                              <div style={{ marginLeft: "-80px" }}>
                                <EditIconSvg />
                              </div>
                              <EyeIcon
                                onClick={() =>
                                  window.open(leadData?.["vattenfallDocument"])
                                }
                              />
                            </div>
                          </div>
                        </div>
                      ) : (
                        <>
                          <div style={{ display: "flex", gap: "0px" }}>
                            <input
                              type="file"
                              onChange={(e) => handleUploadDoc(e)}
                            />
                            <div style={{ margin: "0 0 0 -60px" }}>
                              <UploadIconR />
                            </div>
                          </div>
                        </>
                      )}
                    </>
                  )}
                </div>
              </div>
            </div>
          </div>
        </div>
      )}
    </>
  );
}
