import React, { useEffect, useState, useCallback } from "react";
import DataTable from "react-data-table-component";
import Select from "react-select";
import DatePicker from "react-datepicker";
import "moment-timezone";
import Moment from "react-moment";
import { TailSpin } from "react-loader-spinner";
import { NavLink } from "react-router-dom";
import "./preojekte.scss";
import {
  ApiGet,
  ApiGetNoAuth,
  ApiPost,
  ApiPut,
} from "../../../helpers/API/ApiData";
import useDebounce from "../../../hooks/useDebounceHook";
import { toast } from "react-toastify";

import Moreicon from "../../../assets/icon/more.png";
import { DragDropContext, Draggable, Droppable } from "react-beautiful-dnd";
import moment from "moment/moment";
import CustomCalendar from "../Calendar/calender";
import Loader from "../../../helpers/loader";

export default function Projekte() {
  const [tickets, setTickets] = useState([]);
  const [modal, setModal] = useState(false);
  const [leadData, setLeadData] = useState();
  // const [id, setId] = useState();
  const [search, setSearch] = useState();
  const [statusModal, setStatusModal] = useState(false);
  const [leadId, setLeadId] = useState();
  const [updatedStaus, setUpdatedStatus] = useState();
  const [reason, setReason] = useState();
  const [error, setError] = useState();
  const [laoding, setLoading] = useState(false);
  const [loadData, setLoadData] = useState(false);
  const [timeSlotModel, setTimeSlotModel] = useState(false);
  const [selectedDate, setSelectedDate] = useState(null);
  const [isOpen, setIsOpen] = useState(true);
  const [dropDownOpen, setDropDownOpen] = useState(false);
  const [selectedSlot, setSelectedSlot] = useState(null);
  const [loadings, setLoadings] = useState(false);
  const [comment, setComment] = useState("");
  const debouncValue = useDebounce(search, 400);

  const handleOnChange = (date) => {
    setSelectedDate(date);
    setIsOpen(false);
    setDropDownOpen(true);
  };

  const handleSelect = (slot) => {
    setSelectedSlot(slot);
  };

  const sendReminder = async () => {
    try {
      setLoadings(true);
      const Dates = moment(selectedDate).format("YYYY-MM-DD");
      const sendDate = new Date(
        `${Dates}` + "T" + `${selectedSlot}` + ":00.000Z"
      ).toISOString();

      const body = {
        dateTime: sendDate,
        leadId: timeSlotModel?.leadId?._id,
        comment: comment,
      };
      let response = await ApiPost(`selectDateTime/selectDateTime`, body);
      setSelectedSlot(null);
      setDropDownOpen(false);
      setSelectedDate(null);
      setTimeSlotModel(false);
      setComment("");
      setIsOpen(true);
      toast.success("Erinnerung geplant");
    } catch (error) {
      console.log(error);
    } finally {
      setLoadings(false);
    }
  };

  const ALL_STATUS = [
    { id: "11", status: "offen" },
    { id: "20", status: "NICHT ERREICHT" },
    { id: "21", status: "TERMINIERT" },
    { id: "12", status: "ZEITLICHE VERZÖGERUNG" },
    { id: "12", status: "SPÄTER ANRUFEN" },
    { id: "13", status: "AUFMAß" },
    { id: "14", status: "ANGEBOTSBESPRECHUNG" },
    { id: "15", status: "ANGEBOTSBESPRECHUNG 2" },
    { id: "16", status: "ABSAGE" },
    { id: "17", status: "VERKAUFT" },
    { id: "19", status: "STORNO" },
  ];

  const currentUrl = window.location.href;
  const url = new URL(currentUrl);
  const id = url.searchParams.get("id");

  const getStatusWiseData = async () => {
    try {
      setLoadData(true);
      if (id) {
        const response = await ApiGet(
          `vattenfall/getIdUserConformLead?id=${id}&vattenfallType=project-pv${debouncValue ? `&search=${debouncValue}` : ""
          }`
        );
        setTickets(response?.data?.payload?.findConform);
      } else {
        const response = await ApiGet(
          `vattenfall/getConformLead?vattenfallType=project-pv${debouncValue ? `&search=${debouncValue}` : ""
          }`
        );
        setTickets(response?.data?.payload?.findConform);
      }
    } catch (error) {
      toast.error("error", error.message);
    } finally {
      setLoadData(false);
    }
  };

  useEffect(() => {
    getStatusWiseData();
  }, [id, debouncValue]);

  const getTicketsPerStatus = useCallback(
    (newStatus) => {
      const data = tickets.filter((ticket) => ticket._id === newStatus);
      return data?.[0]?.data;
    },
    [tickets, id]
  );

  const totalLengthTicket =
    tickets && tickets.reduce((sum, item) => sum + item?.data?.length, 0);

  const updateStatus = async (source, destination) => {
    try {
      let body = {
        status: destination?.droppableId,
      };
      let resp = await ApiPut(
        `vattenfall/updateLeadVatten?id=${source?.index}`,
        body
      );
      toast.success("status updated");
      getStatusWiseData();
    } catch (error) {
      toast.error(error?.message);
    }
  };

  const TicketItem = React.memo(({ item, index }) => {
    const updatedAt = new Date(item?.leadId?.updatedAt);
    const currentDate = new Date();
    const timeDifferenceInHours = (currentDate - updatedAt) / (1000 * 60 * 60);

    const isWithinLast48Hours =
      timeDifferenceInHours >= 48 && item?.leadId?.status == "offen";

    return (
      <>
        <Draggable
          draggableId={item?.leadId._id}
          index={item?.leadId._id}
          key={index}
        >
          {(provided) => (
            <div
              className={
                isWithinLast48Hours
                  ? "projeckt-ticket-main-body-box isUpdatd"
                  : "projeckt-ticket-main-body-box "
              }
              style={{ background: "red" }}
              ref={provided.innerRef}
              {...provided.draggableProps}
              {...provided.dragHandleProps}
            >
              {" "}
              <NavLink to={`/vattenlead?id=${item?.leadId._id}&type=project-pv`}>
                <div className="projeckt-ticket-main-body-box-header">
                  <div className="projeckt-ticket-main-body-box-header-left-eye">
                    <div className="projeckt-ticket-main-body-box-header-left-eye-circle">
                      <svg
                        focusable="false"
                        aria-label="Open issue"
                        className="Octicon-sc-9kayk9-0 cRyBKI"
                        role="img"
                        viewBox="0 0 16 16"
                        width="16"
                        height="16"
                        fill="currentColor"
                      >
                        <path d="M8 9.5a1.5 1.5 0 1 0 0-3 1.5 1.5 0 0 0 0 3Z"></path>
                        <path d="M8 0a8 8 0 1 1 0 16A8 8 0 0 1 8 0ZM1.5 8a6.5 6.5 0 1 0 13 0 6.5 6.5 0 0 0-13 0Z"></path>
                      </svg>
                    </div>
                    <span>Lead generiert: {item?.leadId?.benutzername}</span>
                  </div>
                </div>
              </NavLink>
              <div className="projeckt-ticket-main-body-box-links"> </div>
              <div style={{ display: "flex", justifyContent: "space-between" }}>
                <div style={{ color: "#000" }}>
                  Kunde: {item?.leadId?.name + " " + item?.leadId?.nachname}
                </div>

                {!id && (
                  <div className="follow-upbutton">
                    <button onClick={() => setTimeSlotModel(item)}>
                      Follow-up
                    </button>
                  </div>
                )}
              </div>
              <NavLink to={`/vattenlead?id=${item?.leadId._id}&type=project-pv`}>
                <div className="projeckt-ticket-main-body-box-links-button">
                  <a href="/">
                    <button type="button">
                      {item?.leadId?.status.toLocaleUpperCase()}
                    </button>
                  </a>
                </div>
              </NavLink>
              {(item?.leadId?.reason &&
                item?.leadId?.status.toLocaleUpperCase() == "STORNO") ||
                (item?.leadId?.reason &&
                  item?.leadId?.status.toLocaleUpperCase() == "ABSAGE" && (
                    <div
                      className="projeckt-ticket-main-body-box-links-buttons"
                      style={{ paddingTop: "5px" }}
                    >
                      <button type="button" style={{ backgroundColor: "red" }}>
                        {item?.leadId?.reason?.toLocaleUpperCase()}
                      </button>
                    </div>
                  ))}
              <div className="projeckt-ticket-main-body-box-links-ticket-button">
                <NavLink to={`/vattenlead?id=${item?.leadId._id}&type=project-pv`}>
                  <button type="button">
                    {item?.leadId?.strabe || "-"} {item?.leadId?.pLZ || "-"}{" "}
                    {item?.leadId?.location || "-"}
                  </button>
                </NavLink>
                <NavLink to={`/vattenlead?id=${item?.leadId._id}&type=project-pv`}>
                  <button type="button">
                    {moment(item?.leadId?.createdAt).format("MMM D, YYYY")}
                  </button>
                </NavLink>
              </div>
            </div>
          )}
        </Draggable>
      </>
    );
  });

  const onDragEnd = async (result) => {
    const { destination, source } = result;

    const newTickets = [...tickets];

    const columnArray = newTickets.find(
      (item) => item?._id === source.droppableId
    )?.data;

    const draggedTicket = columnArray?.find(
      (item) => item.leadId._id === source.index
    );

    if (!destination || destination.droppableId === source.droppableId) return;

    if (
      source.droppableId?.startsWith("TERMINIERT") &&
      destination.droppableId == "offen"
    )
      return;

    if (source.droppableId == "offen" && destination.droppableId != "STORNO") {
      setModal(!modal);
      setLeadData(draggedTicket?.leadId);
      return null;
    }

    if (
      destination.droppableId == "STORNO" ||
      destination.droppableId == "ABSAGE"
    ) {
      setStatusModal(!statusModal);
      setLeadId(source?.index);
      setUpdatedStatus(destination?.droppableId);
      return;
    }

    const addTicketToColumn = () => {
      const isColumnExist = newTickets?.find(
        (item) => item?._id === destination.droppableId
      );

      const updatedTickets = newTickets?.map((item) => {
        if (isColumnExist && item._id === destination.droppableId) {
          return {
            ...item,
            data: [...item.data, draggedTicket],
          };
        }

        if (item._id === source.droppableId) {
          const updatedData = item.data.filter(
            (ticket) => ticket._id !== draggedTicket._id
          );
          return {
            ...item,
            data: updatedData,
          };
        }

        return item;
      });

      if (!isColumnExist) {
        const newColumn = {
          _id: destination.droppableId,
          data: [draggedTicket],
        };
        updatedTickets.push(newColumn);
      }
      setTickets(updatedTickets);
    };
    addTicketToColumn();
    await updateStatus(source, destination);
  };

  const resonData = [
    { id: 1, reason: "Kein Interesse an Photovoltaik" },
    { id: 2, reason: "Finanzielle Einschränkungen" },
    { id: 3, reason: "Kein Bedarf aufgrund des geringen Stromverbrauch" },
    { id: 4, reason: "Falsche preisliche Vorstellung" },
    {
      id: 5,
      reason:
        "Keine Installationsmöglichkeit / Projekt kann  nicht umgesetzt werden",
    },
    { id: 6, reason: "nur aus Höflichkeit eingetragen" },
    { id: 7, reason: "Keine Entscheidungskompetenz" },
    { id: 8, reason: "Veränderung der Lebensumstände" },
    { id: 9, reason: "Umsetzung > 6 Monate" },
    { id: 10, reason: "Kunde nach min. 6 Anrufversuchen nicht erreichbar." },
    { id: 11, reason: "Kontaktdaten falsch." },
  ];

  const validation = () => {
    let isValid = true;
    let errors = {};
    if (!reason || reason?.trim() === "") {
      errors["reason"] = "Bitte überprüfe deine Eingabe";
      isValid = false;
    }

    setError(errors);
    return isValid;
  };

  const updateReason = async () => {
    if (validation()) {
      setLoading(true);
      try {
        let body = {
          status: updatedStaus,
          reason: reason,
        };
        let resp = await ApiPut(
          `vattenfall/updateLeadVatten?id=${leadId}`,
          body
        );

        toast.success("status updated");
        getStatusWiseData();
        setReason("");
      } catch (error) {
        toast.error(error?.message);
      } finally {
        setStatusModal(!statusModal);
        setLoading(false);
      }
    }
  };

  const handleChange = (e) => {
    setReason(e.target.value);
    setError("");
  };
  const generateTimeSlots = (selectedDateStr, minTime = 7 * 60) => {
    const slots = [];
    const endTime = 23 * 60;

    const formatTime = (minutes) => {
      const hours = Math.floor(minutes / 60);
      const mins = minutes % 60;
      const displayHours = hours < 10 ? `0${hours}` : hours;
      const displayMinutes = mins < 10 ? `0${mins}` : mins;
      return `${displayHours}:${displayMinutes}`;
    };

    const [day, month, year] = selectedDateStr?.split("/").map(Number);
    const selectedDate = new Date(year, month - 1, day);
    const currentDate = new Date();
    const currentMinutes =
      currentDate.getHours() * 60 + currentDate.getMinutes();
    const isToday = selectedDate.toDateString() === currentDate.toDateString();

    while (minTime < endTime) {
      if (!isToday || minTime >= currentMinutes) {
        const timeSlot = formatTime(minTime);
        slots.push(timeSlot);
      }
      minTime += 15;
    }

    return slots;
  };
  const ourFormattedDay = moment(selectedDate).format("D/M/YYYY");
  const timeslots = selectedDate && generateTimeSlots(ourFormattedDay);
  return (
    <>
      {loadData && <Loader />}
      <div className="search-trello-input" style={{ display: "flex", gap: "20px" }}>
        <input
          type="text"
          name="search"
          onChange={(e) => setSearch(e.target.value)}
          placeholder="Suche"
        />
        <button className="redirect-but-dddd" style={{ color: "#fff" }}>
          <a style={{ whiteSpace: "nowrap" }} href={`/add-new?type=project-pv`}>+ Lead</a>
        </button>
      </div>
      <DragDropContext onDragEnd={onDragEnd}>
        <div className="projekte-container">
          <div className="projekct-ticket-main-layout">
            <div className="projekct-ticket-main">
              {ALL_STATUS.map((status, id) => (
                <Droppable droppableId={status.status} key={id} index={id}>
                  {(provided) => (
                    <div
                      className="projeckt-ticket-box"
                      ref={provided.innerRef}
                      {...provided.droppableProps}
                    >
                      <div className="projeckt-ticket-head">
                        <div className="projeckt-card-header-left-eye-content">
                          <div className="projeckt-header-circle"></div>
                          <p>{status.status?.toLocaleUpperCase()}</p>
                          <div className="projeckt-header-numericks">
                            <span>
                              {getTicketsPerStatus(status.status)?.length || 0}{" "}
                              / {totalLengthTicket}
                            </span>
                          </div>
                        </div>
                        <div className="projeckt-ticket-menu-icon">
                          <img src={Moreicon} alt="Moreicon" />
                        </div>
                      </div>
                      <div className="this-title-header"></div>
                      <div className="projeckt-ticket-main-body">
                        {getTicketsPerStatus(status.status)?.map(
                          (item, idx) => (
                            <TicketItem item={item} index={idx} key={idx} />
                          )
                        )}
                      </div>
                    </div>
                  )}
                </Droppable>
              ))}
            </div>
          </div>
        </div>
      </DragDropContext>

      {modal && (
        <>
          <div className="model-design-meeting">
            <div className="model-design-content">
              <div className="close-icon" onClick={() => setModal(!modal)}>
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="30px"
                  height="30px"
                  viewBox="0 0 24 24"
                  fill="none"
                >
                  <path
                    d="M20.7457 3.32851C20.3552 2.93798 19.722 2.93798 19.3315 3.32851L12.0371 10.6229L4.74275 3.32851C4.35223 2.93798 3.71906 2.93798 3.32854 3.32851C2.93801 3.71903 2.93801 4.3522 3.32854 4.74272L10.6229 12.0371L3.32856 19.3314C2.93803 19.722 2.93803 20.3551 3.32856 20.7457C3.71908 21.1362 4.35225 21.1362 4.74277 20.7457L12.0371 13.4513L19.3315 20.7457C19.722 21.1362 20.3552 21.1362 20.7457 20.7457C21.1362 20.3551 21.1362 19.722 20.7457 19.3315L13.4513 12.0371L20.7457 4.74272C21.1362 4.3522 21.1362 3.71903 20.7457 3.32851Z"
                    fill="#000"
                  />
                </svg>
              </div>
              <CustomCalendar
                ownMessage={""}
                setModal={setModal}
                modal={modal}
                leadStatus={"offen"}
                leadData={leadData}
                meetingDate={null}
                appoinmentData={[]}
                type={"add"}
              />
            </div>
          </div>
        </>
      )}

      {statusModal && (
        <>
          <div className="dropdown-modal">
            <div className="dropdown-modal-md">
              <div className="header-align">
                <div className="headfer-design">
                  <p>Stornogrund </p>
                </div>
                <div
                  className="close-icon"
                  onClick={() => {
                    setStatusModal(!statusModal);
                    setReason("");
                    setError("");
                  }}
                >
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="20px"
                    height="20px"
                    viewBox="0 0 24 24"
                    fill="none"
                  >
                    <path
                      d="M20.7457 3.32851C20.3552 2.93798 19.722 2.93798 19.3315 3.32851L12.0371 10.6229L4.74275 3.32851C4.35223 2.93798 3.71906 2.93798 3.32854 3.32851C2.93801 3.71903 2.93801 4.3522 3.32854 4.74272L10.6229 12.0371L3.32856 19.3314C2.93803 19.722 2.93803 20.3551 3.32856 20.7457C3.71908 21.1362 4.35225 21.1362 4.74277 20.7457L12.0371 13.4513L19.3315 20.7457C19.722 21.1362 20.3552 21.1362 20.7457 20.7457C21.1362 20.3551 21.1362 19.722 20.7457 19.3315L13.4513 12.0371L20.7457 4.74272C21.1362 4.3522 21.1362 3.71903 20.7457 3.32851Z"
                      fill="#000"
                    />
                  </svg>
                </div>
              </div>
              <div className="body-part-data">
                <label>
                  Stornogrund <span style={{ color: "red" }}>*</span>
                </label>
                <select name="reason" onChange={(e) => handleChange(e)}>
                  <option value={""}>Stronogrund auswählen</option>
                  {resonData?.map((item) => {
                    return <option value={item?.reason}>{item?.reason}</option>;
                  })}
                </select>

                <span className="errorclass">{error?.reason}</span>
              </div>

              <div className="modl-dfot-design">
                <button onClick={updateReason}>
                  {laoding ? (
                    <TailSpin color="#FFF" height={15} width={15} />
                  ) : (
                    "einreichen"
                  )}
                </button>
              </div>
            </div>
          </div>
        </>
      )}

      {timeSlotModel && (
        <div className="dropdown-modalspp">
          <div className="dropdown-modal-mds">
            <div className="header-aligns">
              <div className="headfer-designs">
                <p>EWiedervorlage definieren</p>
              </div>
              <div
                className="close-icons"
                onClick={() => {
                  setTimeSlotModel();
                  setSelectedSlot(null);
                  setDropDownOpen(false);
                  setSelectedDate(null);
                  setIsOpen(true);
                }}
              >
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="20px"
                  height="20px"
                  viewBox="0 0 24 24"
                  fill="none"
                >
                  <path
                    d="M20.7457 3.32851C20.3552 2.93798 19.722 2.93798 19.3315 3.32851L12.0371 10.6229L4.74275 3.32851C4.35223 2.93798 3.71906 2.93798 3.32854 3.32851C2.93801 3.71903 2.93801 4.3522 3.32854 4.74272L10.6229 12.0371L3.32856 19.3314C2.93803 19.722 2.93803 20.3551 3.32856 20.7457C3.71908 21.1362 4.35225 21.1362 4.74277 20.7457L12.0371 13.4513L19.3315 20.7457C19.722 21.1362 20.3552 21.1362 20.7457 20.7457C21.1362 20.3551 21.1362 19.722 20.7457 19.3315L13.4513 12.0371L20.7457 4.74272C21.1362 4.3522 21.1362 3.71903 20.7457 3.32851Z"
                    fill="#000"
                  />
                </svg>
              </div>
            </div>
            <div className="body-part-datas">
              <label>
                Wählen Sie Datum und Uhrzeit{" "}
                <span style={{ color: "red" }}>*</span>
              </label>
              <div className="react-date-cus">
                <DatePicker
                  selected={selectedDate}
                  placeholderText="Datum"
                  onChange={handleOnChange}
                  onKeyDown={(e) => e.preventDefault()}
                  minDate={new Date()}
                  dateFormat="dd/MM/yyyy"
                  className="dateaaaaaaa"
                  open={isOpen}
                  onFocus={() => setIsOpen(true)}
                  onClickOutside={() => {
                    setIsOpen(false);
                  }}
                />
              </div>
              <div className="custom-dropdown">
                <div
                  className="dropdown-header"
                  onClick={() => setDropDownOpen(!dropDownOpen)}
                >
                  {selectedSlot || "Uhrzeit (Wiedervorlage) "}
                  <span className={`dropdown-arrow `}>▼</span>
                </div>
                {dropDownOpen && (
                  <div className="custom-dropdown-menu-main">
                    {timeslots?.map((slot, index) => (
                      <div
                        key={index}
                        className="dropdown-item"
                        onClick={() => {
                          handleSelect(slot);
                          setDropDownOpen(false);
                        }}
                      >
                        {slot}
                      </div>
                    ))}
                  </div>
                )}
              </div>
              <div className="react-date-cus">
                <textarea
                  placeholder="Notiz hinzufügen"
                  className="dateaaaaaaaaaa"
                  style={{ height: "auto" }}
                  value={comment}
                  onChange={(e) => setComment(e.target.value)}
                />
              </div>
            </div>

            <div
              className="modl-dfot-designs"
              style={{ display: "flex", gap: "10px" }}
            >
              <button onClick={sendReminder}>
                {loadings ? (
                  <TailSpin color="#FFF" height={15} width={15} />
                ) : (
                  "speichern"
                )}
              </button>
            </div>
          </div>
        </div>
      )}
    </>
  );
}
